<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="12">
          <v-text-field
            prepend-icon="fa-search" 
            label="Search ingredients by name"
            solo
            v-model="query" 
            @keyup.enter="showFood"
          ></v-text-field>
          <!-- <v-btn
            text
            color="deep-purple accent-4"
          >
            Search
          </v-btn> -->
        </v-col>
    </v-row>

    <v-alert type="info" v-if="resultCount == 0">
      No results found matching your query. Try searching by a different name.
    </v-alert>
    <v-alert type="success" v-if="resultCount > 0">
      {{resultCount}} ingredients have been found matching your criteria.
    </v-alert>

    <IngredientGrid :items="results" />

    <div v-if="!resultCount && popularFood">
      <v-card-title class="headline">Popular ingredients</v-card-title>
      <v-card-text>Explore recipes by popular ingredients.</v-card-text>
      <IngredientGrid :items="popularFood" />
    </div>
    
    <div v-if="categories && categories.length">
      <h3 class="headline mt-4">Browse by category</h3>
      <p>Explore all ingredients by their category.</p>
      <GenericCardGrid :items="categories" v-slot="slotProps">
        <v-card color="secondary" dark :to="{name: 'ingredientCategory', params: {id: slotProps.item.id}}">
          <v-card-title :class="{'title-s' : $vuetify.breakpoint.xs }">
            {{slotProps.item.name}}
          </v-card-title>
        </v-card>
      </GenericCardGrid>
    </div>

  </div>
</template>

<script>
import IngredientGrid from "@/components/IngredientGrid.vue";
import GenericCardGrid from "@/components/GenericCardGrid.vue";
import foodService from "@/services/foodService";

export default {
  name: "Ingredients",
  components: {
    IngredientGrid,
    GenericCardGrid,
  },
  props: {
    msg: String
  },
  data() {
    return {
      results: [],
      resultCount: null,
      popularFood: null,
      categories: null,
      query: null
    };
  },
  async mounted() {
    this.loadPopularFood();
    
    var result = await foodService.getCategories();
    this.categories = result.data.data;
  },
  methods: {
    showFood() {
      console.log("search", this.query);
      foodService.search(this.query).then(response => {
        this.results = response.data.data;
        this.resultCount = response.data.meta.count;
      }).catch(() => {
        this.results = [];
        this.resultCount = null;
      });
    },
    loadPopularFood() {
      foodService.getPopular(25).then(response => {
        this.popularFood = response.data.data;
      }).catch(() => {
        this.popularFood = null;
      });
    }
  },
  watch: {
  }
};
</script>
